/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('#menu-main .menu-item-has-children > a').on('click', function (e) {
          e.preventDefault();
        });

        $('header .menu-toggler').on('click', function() {
          $(this).toggleClass('active');
          $(this).find('.toggler-text').toggleClass('hidden');
          $('#menu-main, #menu-user-xs, .header-info .partner').toggle();
        });

        $('#menu-main .menu-item-has-children').on('click', function () {
          if(window.innerWidth < 768) {
            $(this).toggleClass('opened');
            $(this).find('.sub-menu').toggle();
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'archive': {
      init: function() {
        $('.woocommerce-category-filter select').on('change', function() {
          location.href = $(this).val();
        });

        $('.add_to_cart_button').on('click', function() {
          var $countElement = $('li.menu-warenkorb .cart-count');
          var count = parseInt($countElement.text());
          $countElement.html(count+1);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
